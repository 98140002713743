<template>
  <div>    
    <center> 
         <br><br>
        <b-row class="justify-content-center">
            <b-col cols="10" md="5" style="background: #0c273a; border-radius: 10px;" >  
                <b-row class="justify-content-center" style=" margin: 5px; margin-left: -30px;" >
                <b-col cols="10" md="2" class="mt-2">  
                    <u style="color:#fff; font-size:18px; cursor: pointer;"  @click="cambiaTab(0)">IDPDV</u>
                </b-col>
                <b-col cols="10" md="2" class="mt-2">  
                    <u style="color:#fff; font-size:18px; cursor: pointer; margin-left: -30px;" @click="cambiaTab(3)">EJECUTIVO</u>
                </b-col>
                <b-col cols="10" md="2" class="mt-2">  
                    <u style="color:#fff; font-size:18px; cursor: pointer;" @click="cambiaTab(2)">COORDINADOR</u>
                </b-col> 
                <b-col cols="10" md="1"  class="mt-1 ">  </b-col>
                <b-col cols="10" md="5"  class="mt-1 ">  
                    <input v-model="buscar_gerente" type="text" class="form-control" placeholder="Buscar"  v-if="tab == 0"  style="border-radius: 5px; height: 90%; font-size:20px;">
                    <input v-model="buscador_ejecutivo" type="text" class="form-control" placeholder="Buscar"  v-if="tab == 3"  style="border-radius: 5px; height: 90%; font-size:20px;">
                    <input v-model="buscar_coordinador" type="text" class="form-control" placeholder="Buscar" v-if="tab == 2"  style="border-radius: 5px;  height: 90%; font-size:20px;" >
                </b-col> 
                </b-row>  
            </b-col> 
        </b-row>
    </center>
    <br> <br>
    <b-row class="justify-content-center">
        <b-col cols="11" md="10" class="mt-2">    
            <b-tabs content-class="mt-3" justified style="font-size:26px;" >
                <b-tab title="Regiones" :active="region_pdv == 'Todas' ? true : false" @click="cambiaRegion('Todas')"></b-tab> 
                <b-tab title="Centro F." :active="region_pdv == 'CENTRO FORANEO' ? true : false" @click="cambiaRegion('CENTRO FORANEO')"></b-tab>
                <b-tab title="Centro M." :active="region_pdv == 'CENTRO METROPOLITANO' ? true : false" @click="cambiaRegion('CENTRO METROPOLITANO')"></b-tab>
                <b-tab title="Norte" :active="region_pdv == 'NORTE' ? true : false" @click="cambiaRegion('NORTE')"></b-tab>
                <b-tab title="Occidente" :active="region_pdv == 'OCCIDENTE' ? true : false" @click="cambiaRegion('OCCIDENTE')"></b-tab>
                <b-tab title="Sur" :active="region_pdv == 'SUR' ? true : false" @click="cambiaRegion('SUR')"></b-tab>
            </b-tabs>    
        </b-col>
    </b-row> 
    <div style="background:#d6eaf83b; margin-top: -35px;"> 
     <b-row class="justify-content-center mt-5">
        <b-col cols="11" md="11" lg="11" class="text-center mt-3">
            <!-- <br> <br v-if="!isMobile"> -->
            <center v-if="loader">
                <SpinnerLoader/>
                <p style="color:#000;">Procesando información, no cierres el navegador <br> espera un momento...</p>
            </center>  
            <template v-if="tab == 0">   

                 <div>
                        <table class="table table-borderless mt-3 text-center" style="background-color:rgba(0, 0, 0, 0);">
                            <thead class="text-white">
                            <tr> 
                                <td style="width:7%; font-size:24px;  color:#0b2739;"  class="text-center"><b><u>Lugar</u></b></td>  
                                <td style="width:10%;  font-size:24px;  color:#0b2739;" class="text-center"><b><u>IDPDV</u></b></td> 
                                <td style="width:20%; font-size:24px;  color:#0b2739;"><b><u>CAC</u></b></td>   
                                <td style="width:35%;  font-size:24px;  color:#0b2739;" class="text-center"><b><u>Cumplimiento</u></b> </td>   
                                <td style="width:7%;  font-size:24px;  color:#0b2739;"><b><u>Puntos</u></b></td> 
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr v-for="(ran, index) in gerente" :key="ran.id">
                               <td  class="text-center">
                                  <div style="color: #fff; background: #3bcb96; ">
                                     <b-row class="justify-content-center" style="margin-top:-100px">
                                        <b-col cols="10"  :style="index == 0 ? 'margin-top:45px;' : 'margin-top:17px;'"> 
                                             <br > <br > <br> 
                                            <template v-if="!buscar_gerente">
                                              <img v-if="index < 10" :src="'https://convencioncomercial.com.mx/plato/'+ index + '.png'" class="rounded" alt="Image" style="width:55%; margin-top:-2px;">  
                                              <h5 v-if="index > 9" style="color: #fff; margin-top:4px;" >
                                                    {{index + 1  }}
                                               </h5>  
                                            </template>
                                            <template v-else>
                                                <img v-if="ran.lugar < 10" :src="'https://convencioncomercial.com.mx/plato/'+ (ran.lugar - 1)  + '.png'" class="rounded" alt="Image" style="width:55%;  margin-top:-2px;">  
                                                <h4 v-if="ran.lugar > 9" style="color: #fff; margin-top:4px;" >
                                                    {{ran.lugar}}
                                                </h4>  
                                            </template>
                                             <br > <br > <br>
                                        </b-col>
                                     </b-row>
                                  </div>
                                </td> 
                                <td style="color: #0c273a; font-size: 24px;"> 
                                    <div  :style="index == 0 ? 'margin-top:20px;' : 'margin-top:2px;'">
                                     {{ran.idpdv}}  
                                    </div>
                                </td>
                                <td style="color: #0c273a; font-size: 24px;"> 
                                    <div :style="index == 0 ? 'margin-top:20px;' : 'margin-top:2px;'">
                                       {{ran.tienda}} 
                                    </div>
                                </td> 
                                <td> 
                                    <b style="font-size:13px;">
                                       <b-row class="justify-content-center" style="margin-top:-75px;"> 
                                        <b-col cols="10">
                                            <b-row  > 
                                                <b-col cols="2" >
                                                     <br><br> <span  style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Marzo</u></span><br> 
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:28px;'" class="mt-5" >
                                                        <b>{{ran.marzo}}</b> 
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col> 
                                                <b-col cols="2">
                                                    <br><br> <span style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Abril</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:28px;'"  class="mt-5">
                                                        <b>{{ran.abril}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col>
                                                <b-col cols="2">
                                                    <br><br> <span style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Mayo</u></span><br> 
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:28px;'"  class="mt-5">
                                                        <b>{{ran.mayo}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                </b-col>
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Junio</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.junio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col> 
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Julio</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.julio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col>  
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Agosto</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.agosto}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col>  
                                            </b-row>
                                        </b-col> 
                                       </b-row>
                                    </b> 
                                </td>  
                                <td> 
                                    <div style="color: #fff; background: #3bcb96; ">
                                        <b-row class="justify-content-center" :style="index == 0 ? 'margin-top:-105px' : 'margin-top:-119px'">
                                        <b-col cols="10"> 
                                            <br v-if="!isMobile"><br v-if="!isMobile"><br> 
                                            <br><br> 
                                            <b style="font-size: 28px;">{{ran.total}} </b> 
                                            <br > <br > <br>
                                        </b-col>
                                        </b-row>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>  
                        <!-- <template v-if="region_pdv == 'Todas'">
                            <br>
                            <h6 v-if=" vermas < arrayGerente2.length" style="cursor: pointer;" @click="getcampanas(vermas + 10)"><u>Ver más <b-spinner v-if="loader" small></b-spinner></u></h6>
                            <br>
                            <span v-if="vermas > 10 && !buscar_gerente" @click="subirArriba()" class="mdi mdi-arrow-up-thick " style="color:#fff; cursor: pointer;"> Regresar al Inicio</span> 
                        </template> -->
                 </div>      

            </template>
            <template v-if="tab == 3"> 
                 <div>
                        <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr>  
                                <td style="width:3%; font-size:24px;  color:#0b2739;" class="text-center" ><b><u>Lugar</u></b></td>  
                                <td style="width:12%;  font-size:24px;  color:#0b2739;"><b><u>Ejecutivo</u></b></td>    
                                <td style="width:20%;  font-size:24px;  color:#0b2739;" class="text-center"><b><u>Cumplimiento</u></b></td>       
                                <td style="width:3%;  font-size:24px;  color:#0b2739;"><b><u>Puntos</u></b></td> 
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr v-for="(ran, index) in ejecutivo" :key="ran.id">
                                <td  class="text-center" >
                                  <div style="color: #fff; background: #3bcb96; ">
                                     <b-row class="justify-content-center" style="margin-top:-100px">
                                        <b-col cols="10"  :style="index == 0 ? 'margin-top:35px;' : 'margin-top:17px;'"> 
                                             <br > <br > <br>
                                             <br v-if="isMobile">
                                            <template v-if="!buscador_ejecutivo">
                                                <img v-if="index < 10" :src="'https://convencioncomercial.com.mx/plato/'+ index + '.png'" class="rounded" alt="Image" style="width:60%; margin-top:-2px;">  
                                               <h5 v-if="index > 9" style="color: #fff; margin-top:4px;" >
                                                    {{index + 1  }}
                                                </h5>  
                                            </template>
                                            <template v-else>
                                                <img v-if="ran.lugar < 10" :src="'https://convencioncomercial.com.mx/plato/'+ (ran.lugar - 1)  + '.png'" class="rounded" alt="Image" style="width:60%;  margin-top:-2px;">  
                                                <h4 v-if="ran.lugar > 9" style="color: #fff; margin-top:4px;" >
                                                    {{ran.lugar}}
                                                </h4>  
                                            </template>
                                             <br > <br > <br> 
                                        </b-col>
                                     </b-row>
                                  </div>
                                </td> 
                                <td :colspan="isMobile ? '6' : ''" style="color: #0c273a; font-size: 24px;"> 
                                    <div  :style="index == 0 ? 'margin-top:20px;' : 'margin-top:2px;'">
                                     {{ran.ejecutivo}}  
                                    </div>
                                </td> 
                                <td :colspan="isMobile ? '13' : ''"> 
                                    <b style="font-size:13px;">
                                       <b-row class="justify-content-center" style="margin-top:-75px;"> 
                                        <b-col cols="10">
                                            <b-row  > 
                                                <b-col cols="2" >
                                                     <br><br> <span  style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Marzo</u></span><br> 
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:28px;'" class="mt-5" >
                                                        <b>{{ran.marzo}}</b> 
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col> 
                                                <b-col cols="2">
                                                    <br><br> <span style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Abril</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:28px;'"  class="mt-5">
                                                        <b>{{ran.abril}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col>
                                                <b-col cols="2">
                                                    <br><br> <span style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Mayo</u></span><br> 
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:28px;'"  class="mt-5">
                                                        <b>{{ran.mayo}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                </b-col>
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Junio</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.junio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col> 
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Julio</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.julio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col>  
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Agosto</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.agosto}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col>  
                                            </b-row>
                                        </b-col> 
                                       </b-row>
                                    </b> 
                                </td>
                                <td> 
                                    <div style="color: #fff; background: #3bcb96; ">
                                        <b-row class="justify-content-center" :style="index == 0 ? 'margin-top:-105px' : 'margin-top:-122px'">
                                        <b-col cols="10">
                                            <br v-if="!isMobile"><br v-if="!isMobile"><br><br> <br>
                                            <b style="font-size: 28px;">{{ran.total}} </b> 
                                            <br > <br >
                                        </b-col>
                                        </b-row>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table> 
                 </div> 
            </template>
            <template v-if="tab == 2"> 
                 <div>
                        <table class="table table-borderless mt-3 text-center">
                            <thead class="text-white">
                            <tr> 
                                <td style="width:3%; font-size:24px;  color:#0b2739;" class="text-center" ><b><u>Lugar</u></b></td> 
                                <td style="width:12%;  font-size:24px;  color:#0b2739;"><b><u>Coordinador</u></b></td>     
                                <td style="width:20%;  font-size:24px;  color:#0b2739;" class="text-center"><b><u>Cumplimiento</u></b> </td>        
                                <td style="width:3%;  font-size:24px;  color:#0b2739;"><b><u>Puntos</u></b></td> 
                            </tr>
                            </thead>
                            <tbody>
                            <br>
                            <tr v-for="(ran, index) in coordinador" :key="ran.id" >
                                <td  class="text-center">
                                  <div style="color: #fff; background: #3bcb96; ">
                                     <b-row class="justify-content-center" style="margin-top:-100px">
                                        <b-col cols="10" :style="index == 0 ? 'margin-top:35px;' : 'margin-top:17px;'"> 
                                             <br > <br > <br>
                                            <template v-if="!buscar_coordinador">
                                                <img v-if="index < 10" :src="'https://convencioncomercial.com.mx/plato/'+ index + '.png'" :class=" isMobile ? 'rounded' : 'rounded'" alt="Image" style="width:60%; margin-top:-2px;">  
                                                <h5 v-if="index > 9" style="color: #fff; margin-top:4px;" >
                                                    {{index + 1  }}
                                                </h5>  
                                            </template>
                                            <template v-else>
                                                <img v-if="ran.lugar < 10" :src="'https://convencioncomercial.com.mx/plato/'+ (ran.lugar - 1)  + '.png'" class="rounded" alt="Image" style="width:60%;  margin-top:-2px;">  
                                                <h4 v-if="ran.lugar > 9" style="color: #fff; margin-top:4px;" >
                                                    {{ran.lugar}}
                                                </h4>  
                                            </template>
                                             <br > <br > <br> 
                                        </b-col>
                                     </b-row>
                                  </div>
                                </td> 
                                <td style="color: #0c273a; font-size: 24px;"> 
                                    <div :style="index == 0 ? 'margin-top:20px;' : 'margin-top:2px;'">
                                     {{ran.coordinador}}  
                                    </div>
                                </td> 
                                <td :colspan="isMobile ? '13' : ''"> 
                                    <b style="font-size:13px;">
                                       <b-row class="justify-content-center" style="margin-top:-75px;"> 
                                        <b-col cols="10">
                                            <b-row  > 
                                                <b-col cols="2" >
                                                     <br><br> <span  style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Marzo</u></span><br> 
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:28px;'" class="mt-5" >
                                                        <b>{{ran.marzo}}</b> 
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col> 
                                                <b-col cols="2">
                                                    <br><br> <span style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Abril</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:28px;'"  class="mt-5">
                                                        <b>{{ran.abril}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col>
                                                <b-col cols="2">
                                                    <br><br> <span style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Mayo</u></span><br> 
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:28px;'"  class="mt-5">
                                                        <b>{{ran.mayo}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                </b-col>
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Junio</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.junio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col> 
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Julio</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.julio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col>  
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Agosto</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.agosto}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col>  
                                            </b-row>
                                        </b-col> 
                                       </b-row>
                                    </b> 
                                </td>
                                <td> 
                                    <div style="color: #fff; background: #3bcb96; ">
                                        <b-row class="justify-content-center"  :style="index == 0 ? 'margin-top:-105px' : 'margin-top:-122px'">
                                        <b-col cols="10">
                                            <br v-if="!isMobile"><br v-if="!isMobile"><br><br> <br>
                                            <b style="font-size: 28px;">{{ran.total}} </b> 
                                            <br > <br > <br> 
                                        </b-col>
                                        </b-row>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table> 
                 </div> 
            </template>
            <br><br><br>
        </b-col>
     </b-row>
  </div> 
</div>
</template>

<script>
function compare(a, b) {
  if (a.total > b.total) {
    return -1;
  }
  if (a.total < b.total) {
    return 1;
  }
  return 0;
}
import SpinnerLoader from "../../utils/SpinnerLoader.vue";
export default {
  name: 'Propio',
 components:{
    SpinnerLoader
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      arrayGerente:[],
      arrayGerente2:[],
      arrayEjecutivo:[],
      arrayCoordinador:[],
      tab:0,
      colors:'#83c32d',
      buscar_coordinador:"",
      buscar_gerente:"",
      buscador_ejecutivo:"",
      vermas:10,
      region_pdv:"Todas",
      arrarEjeCF:[],
      arrarEjeCM:[],
      arrarEjeN:[],
      arrarEjeO:[],
      arrarEjeS:[],
      arrarCooCF:[],
      arrarCooCM:[],
      arrarCooN:[],
      arrarCooO:[],
      arrarCooS:[],
      arrarGerCF:[],
      arrarGerCM:[],
      arrarGerN:[],
      arrarGerO:[],
      arrarGerS:[],
      array1:[],
      array2:[],
      array3:[]
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
    coordinador(){
     if (this.buscar_coordinador == '') {          
            return this.array2;
        }else{
               return   this.array2.filter(item => {
                    return   item.coordinador?.toLowerCase().includes(this.buscar_coordinador.toLowerCase()) ||
                             item.region?.toLowerCase().includes(this.buscar_coordinador.toLowerCase()) 
                    ;
              });
        }
    },
    gerente(){ 
            if (this.buscar_gerente == '') {          
                return this.array1;
            }else{
                return   this.array1.filter(item => {
                        return   item.idpdv?.toLowerCase().includes(this.buscar_gerente.toLowerCase()) ||
                                item.tienda?.toLowerCase().includes(this.buscar_gerente.toLowerCase()) 
                        ;
                });
            } 
    },
    ejecutivo(){
     if (this.buscador_ejecutivo == '') {          
            return this.array3;
        }else{
               return   this.array3.filter(item => {
                    return   item.ejecutivo?.toLowerCase().includes(this.buscador_ejecutivo.toLowerCase())
                    ;
              });
        }
    }
  },
  methods:{
    cambiaRegion(id){
         this.region_pdv = id;
        this.buscador_ejecutivo = "";
        this.buscar_coordinador = "";
        this.buscar_gerente = "";
         switch (this.region_pdv ) {
            case "Todas":
                this.array1 = this.arrayGerente2;
                this.array2 = this.arrayCoordinador;
                this.array3 = this.arrayEjecutivo;
            break; 
            case "CENTRO FORANEO":
                 this.array1 = this.arrarEjeCF;
                 this.array2 = this.arrarCooCF;
                 this.array3 = this.arrarGerCF; 
            break; 
            case "CENTRO METROPOLITANO":
                this.array1 = this.arrarEjeCM;
                this.array2 = this.arrarCooCM;
                this.array3 = this.arrarGerCM;
            break; 
            case "NORTE":
                this.array1 = this.arrarEjeN;
                this.array2 = this.arrarCooN;
                this.array3 = this.arrarGerN;
            break; 
            case "OCCIDENTE":
                this.array1 = this.arrarEjeO;
                this.array2 = this.arrarCooO;
                this.array3 = this.arrarGerO;
            break; 
            case "SUR":
                this.array1 = this.arrarEjeS;
                this.array2 = this.arrarCooS;
                this.array3 = this.arrarGerS;
            break; 
         }
    },
    cambiaTab(id){
        this.cambiaRegion('Todas');
        this.tab = id; 
        this.buscar_gerente = ""; 
        this.buscar_coordinador = ""; 
    },
    subirArriba(){
      // this.$scrollTo("#subir");
       window.scrollTo(0, -500);
    },
    getcampanas(id){
      this.loader = true;
      this.vermas = id;
      this.buscar_gerente = '';
      this.buscar_coordinador = ''; 
       var url= 'auth/rankinpropio?vermas='+ id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.arrayGerente = data.gerente.sort(compare); 
                this.arrayGerente2 = data.gerente2.sort(compare); 
                this.arrarEjeCF = data.g_centrof.sort(compare); 
                this.arrarEjeCM = data.g_centrom.sort(compare); 
                this.arrarEjeN = data.g_norte.sort(compare); 
                this.arrarEjeO = data.g_occidente.sort(compare); 
                this.arrarEjeS = data.g_sur.sort(compare); 
                this.arrarCooCF = data.c_centrof.sort(compare); 
                this.arrarCooCM = data.c_centrom.sort(compare); 
                this.arrarCooN = data.c_norte.sort(compare); 
                this.arrarCooO = data.c_occidente.sort(compare); 
                this.arrarCooS = data.c_sur.sort(compare); 
                this.arrarGerCF = data.e_centrof.sort(compare); 
                this.arrarGerCM = data.e_centrom.sort(compare); 
                this.arrarGerN = data.e_norte.sort(compare); 
                this.arrarGerO = data.e_occidente.sort(compare); 
                this.arrarGerS = data.e_sur.sort(compare); 
                this.arrayCoordinador = data.coordinador.sort(compare);
                this.arrayEjecutivo = data.ejecutivo.sort(compare);
                this.cambiaRegion('Todas');
                this.loader = false;
            }
        );
    },
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/"); 
    }
  },
  mounted(){
          if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo5", true)
        //  window.scrollTo(0, -200);     
      } 
    this.$bus.$emit("cambia_fondo", true)
    this.getcampanas(this.vermas);
  }
}
</script>
<style>
@media only screen and (min-width: 1000px) 
{
 
}
@media only screen and (min-width: 1300px) 
{
 
}
@media only screen and (min-width: 1400px)
{
 
}
@media only screen and (min-width: 1600px)
{
 
}
@media only screen and  (min-width: 1800px)
{
 
}

.table {
--bs-table-bg: #fff0; 
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #0c273a;
    background-color: #33d951;
    border-color: #33d951;
}

.nav-tabs .nav-link { 
    background: #686c6f;
    border: 4px   solid #019df4;
    border-top-left-radius: 0.8rem;
    color: #fff;
    border-top-right-radius: 0.8rem; 
}

#outer_wrapper {  
    overflow: scroll;  
    width:100%;
}
#outer_wrapper #inner_wrapper {
    width:1000px; /* If you have more elements, increase the width accordingly */
}
#outer_wrapper #inner_wrapper div.box { /* Define the properties of inner block */
    width: 250px;
    height:300px;
    float: left;
    margin: 0 4px 0 0;
    border:1px grey solid;
}

.nav-tabs {
    --bs-border-width: 0px;
}
</style>